import {
  ListItemAvatar,
  ListItemText,
  Avatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemButton
} from "@mui/material";
import { History as RecentIcon } from "@mui/icons-material";

import { entityMap } from "../Search/Hooks";

export default function ListItemEntity({ entity }) {
  const map = {
    recent: {
      leading: { Icon: RecentIcon },
      line1: entity.recentQuery,
      href: `/search?q=${entity.recentQuery}`
    },
    paper: {
      leading: { Icon: entityMap.paper.Icon },
      // leading: { Icon: SearchIcon },
      line1: entity.title,
      // trailing: { Icon },
      // line2: <SubheaderPaper publisher org authors paper={entity} />,
      href: `/docs/${entity.publisher}/${entity.paperID}/paper`
    },
    tag: {
      leading: { Icon: entityMap.task.Icon },
      // leading: { img: `https://cdn.bytez.com/images/v2/${entity.name}.png` },
      line1: entity.name,
      // line2: <SubheaderTask task={entity.name} />,
      // href: `/task/${tasks.url.encode(entity.name)}`
      href: `/search?type=papers&task=${entity.name}`

      // trailing: {
      //   Icon: ({ sx }) => <TaskFilter sx={sx} tag={entity} />
      // }
    },
    model: {
      leading: { Icon: entityMap.model.Icon },
      line1: entity.title,
      line2: entity.publisher,
      href: `/docs/model/${entity.modelId}`
    },
    video: {
      leading: { Icon: entityMap.video.Icon },
      // leading: { videoThumbnail: entity.img },
      line1: entity.title
      // line2: <SubheaderVideo video={entity} />
    }
  };

  const type = entity.__typename?.toLowerCase();
  if (!type) {
    console.warn(entity);
  }
  return type ? <ListItem {...map[type]} /> : undefined;
}

export const ListItem = ({
  dark,
  href,
  line1,
  line2,
  line3,
  line1Sx = {},
  leading = {},
  trailing = {},
  ...props
}) => (
  <ListItemButton
    href={href}
    sx={{
      p: 1,
      height: line3 === undefined ? (line2 === undefined ? 56 : 72) : 88,
      maxHeight: 88
    }}
    {...props}
  >
    {leading.Avatar ? (
      <ListItemAvatar>
        <Avatar
          sx={{
            height: 40,
            width: 40,
            color: "primary.on.container",
            bgcolor: "primary.container",
            typography: "titleMd"
          }}
        >
          <leading.Avatar />
        </Avatar>
      </ListItemAvatar>
    ) : leading.img ? (
      <ListItemAvatar sx={{ pr: 2, pl: 1 }}>
        <Avatar
          variant="rounded"
          src={leading.img}
          sx={{
            height: 56,
            width: "auto",
            color: "primary.on.container",
            bgcolor: "primary.container",
            typography: "titleMd"
          }}
        >
          <leading.Icon />
        </Avatar>
      </ListItemAvatar>
    ) : leading.videoThumbnail ? (
      <ListItemAvatar sx={{ pr: 2 }}>
        <Avatar
          variant="rounded"
          src={leading.videoThumbnail}
          sx={{
            height: 64,
            width: "auto",
            aspectRatio: "16 / 9",
            color: "primary.on.container",
            bgcolor: "primary.container",
            typography: "titleMd"
          }}
        >
          <leading.Icon />
        </Avatar>
      </ListItemAvatar>
    ) : leading.Icon ? (
      <ListItemIcon sx={{ minWidth: "unset", pr: 2, pl: 1 }}>
        <leading.Icon
          sx={{
            width: 24,
            height: 24,
            color: `${dark ? "dark" : ""}.surface.on.variant`
          }}
        />
      </ListItemIcon>
    ) : null}
    <ListItemText
      primary={line1}
      primaryTypographyProps={{
        typography: { compact: "bodyMd", expanded: "bodyLg" },
        color: dark ? "surface.inverse.on" : "surface.on.color",
        textTransform: "capitalize",
        maxWidth: "85%",
        sx: theme => ({ ...theme.typography.lineClamp(1), ...line1Sx })
      }}
      secondary={line2}
      secondaryTypographyProps={{
        variant: "bodyMd",
        color: dark ? "dark.surface.on.variant" : "surface.on.variant",
        sx: theme => theme.typography.lineClamp(1)
      }}
    />
    {trailing.Icon ? (
      <ListItemSecondaryAction>
        <trailing.Icon
          sx={{ color: "surface.on.variant", height: 24, width: 24 }}
        />
      </ListItemSecondaryAction>
    ) : trailing.supportText ? (
      <ListItemSecondaryAction>
        <ListItemText
          primary={trailing.supportText}
          primaryTypographyProps={{
            variant: "labelSm",
            color: "surface.on.variant"
          }}
        />
      </ListItemSecondaryAction>
    ) : null}
  </ListItemButton>
);
