"use client";
import { Box, ButtonBase } from "@mui/material";
import {
  Star as OnIcon,
  StarBorderOutlined as OffIcon
} from "@mui/icons-material";

import { useStore, observer } from "../../service/mobx";
import ToolTip from "../Tooltip";
import useLongPress from "./useLongPress";
import { dialogStar } from "../Dialog/dialogs/appWide/Stars";
import useSignInDialog from "../Dialog/dialogs/appWide/Login";

function Star({ entity = {}, size = "medium" }) {
  const { reader, stars } = useStore();
  const longPressEvents = useStarCallback(entity);
  const selected = stars.map.has(entity.id);
  const Icon = selected ? OnIcon : OffIcon;
  const dimension = size === "small" ? 32 : 40;

  return reader.whiteLabelled || stars.loading || entity.id === undefined ? (
    <Box width={dimension} height={dimension} />
  ) : (
    <ToolTip title="Save">
      <ButtonBase
        {...longPressEvents}
        aria-label="Save"
        sx={theme => ({
          cursor: "pointer",
          width: dimension,
          height: dimension,
          borderRadius: theme.shape.round,
          bgcolor: selected ? "primary.color" : "surface.container.highest",
          transition: theme.transitions.create("all"),
          "&:hover": {
            backgroundImage: `linear-gradient(${theme.palette.alpha(
              selected
                ? theme.palette.primary.on.color
                : theme.palette.primary.color,
              0.08
            )}, ${theme.palette.alpha(
              selected
                ? theme.palette.primary.on.color
                : theme.palette.primary.color,
              0.08
            )})`,
            "& svg": {
              color: selected ? "primary.on.color" : "primary.color"
            }
          }
        })}
      >
        <Icon
          sx={() => ({
            width: size === "small" ? 16 : 24,
            height: size === "small" ? 16 : 24,
            color: selected ? "primary.on.color" : "primary.color"
          })}
        />
      </ButtonBase>
    </ToolTip>
  );
}

export default observer(Star);
export { OffIcon, OnIcon };
export function useStarCallback(entity = {}) {
  const { analytics, dialog, stars, snackbar, user } = useStore();
  const signUpDialog = useSignInDialog("Saving is free for the community");
  const entityId = entity.id;
  const selected = stars.map.has(entityId);
  const openDialog = () =>
    dialog.configure({ ...dialogStar, state: { entity } });
  const longPressEvents = useLongPress(
    user.isAnonymous ? signUpDialog : openDialog,
    user.isAnonymous
      ? signUpDialog
      : function onClick() {
          if (selected) {
            openDialog();
          } else {
            stars.quickSave({ entity });
            snackbar.notify({
              text: "Saved",
              actions: [
                {
                  label: "Undo",
                  onClick: () => {
                    snackbar.set.open(false);
                    stars.quickSave({ entity, save: false });
                  }
                },
                {
                  label: "Add to list",
                  onClick: () => {
                    snackbar.set.open(false);
                    openDialog();
                  }
                }
              ]
            });

            analytics.track.event("Star Save", {
              entity: { type: entity.__typename, id: entity.id }
            });
          }
        }
  );

  return entityId ? longPressEvents : { disabled: true };
}
