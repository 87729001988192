"use client";
import { Fragment } from "react";
import { Stack, CardHeader, Typography, Box } from "@mui/material";

import { useStore } from "../../service/mobx";
import FilledCard from "../Card/Filled";
import { textRank } from "../../service/mobx/utilities";
import Popup from "../Popup";
import ChipAssist from "../Chip/Assist";
import GameBadge from "../GameBadge";
import { CardExternalPaperSize2 } from "./External";
import StackOverflow from "./../StackOverflow";
import { TaskSize1 } from "./Task";
import { menuOptionsPublisher } from "../Menu/menus/Search/Publisher";
import Star from "../Star";
import RenderPaperTitle from "../Markdown/PaperTitle";

export function PaperCardSize1({ paper, entity }) {
  paper ??= entity;

  return (
    <Popup
      Component={() => (
        <PaperCardSize4 tone="surface.container.lowest" paper={paper} />
      )}
    >
      <div>
        <ChipAssist
          label="Paper"
          // StartIcon={TrendingIcon}
          onClick={() => {
            // analytics.track.event
            // user
          }}
        />
      </div>
    </Popup>
  );
}
export function PaperCardSizeSm({ paper, entity, index }) {
  paper ??= entity;

  return (
    <ChipAssist
      label={`${index} - ${paper.title}`}
      // StartIcon={TrendingIcon}
      onClick={() => {
        // analytics.track.event
        // user
      }}
    />
  );
}
export function PaperCardSize2({ paper, tone }) {
  const { reader } = useStore();

  return reader.supportedPublisher.has(paper.publisher) ? (
    <Card paper={paper} tone={tone} sx={{ p: 1 }}>
      <Header org publisher paper={paper} size="small" />
    </Card>
  ) : (
    <CardExternalPaperSize2 paper={paper} />
  );
}
export function PaperCardSize3({ paper, tldr = false, tone, prefetch }) {
  // const Status = null;
  return (
    <Card paper={paper} tone={tone} prefetch={prefetch}>
      <Header org publisher paper={paper} size="small" />
      <Tasks tags={paper.tags} paddingLeft={paper.scoreTrending && !tldr} />
    </Card>
  );
}
export function PaperCardSize4({ paper, tone, tldr = false }) {
  // const Status = null;

  return (
    <Box>
      <Card paper={paper} tone={tone}>
        <Stack spacing={2} display="flex" justifyContent="space-between">
          <Header org authors publisher paper={paper} />
          {tldr ? <Summary size="large" paper={paper} /> : null}
          <Tasks tags={paper.tags} paddingLeft={paper.scoreTrending && !tldr} />
        </Stack>
      </Card>
    </Box>
  );
}
export function PaperCardSize5({ paper, tone, target }) {
  // const Status = null;

  return (
    <Box maxWidth="80ch">
      <Card paper={paper} tone={tone} target={target}>
        <Stack spacing={2} display="flex" justifyContent="space-between">
          <Header size="large" org authors publisher paper={paper} />
          <Summary size="large" paper={paper} />
          <Tasks tags={paper.tags} />
        </Stack>
      </Card>
    </Box>
  );
}

function Card({ children, paper, tone = "surface.container.color", ...props }) {
  const { analytics } = useStore();
  const { publisher, paperID } = paper;

  return (
    <FilledCard
      tone={tone}
      href={`/docs/${publisher}/${paperID}/paper`}
      onClick={() => {
        analytics.track.event("Paper Click", { publisher, paperID });
      }}
      {...props}
    >
      {children}
    </FilledCard>
  );
}

export const Tasks = ({ small, tags, paddingLeft }) =>
  tags?.length ? (
    <Box pl={paddingLeft ? 7 : undefined}>
      <StackOverflow
        max={1}
        entityName="tag"
        small={small}
        Card={TaskSize1}
        cards={tags}
      />
    </Box>
  ) : null;

export function SubHeader({
  paper,
  org = false,
  authors = false,
  publisher = false
}) {
  const { utilities } = useStore();

  try {
    const authorArray = paper.authorArray ?? JSON.parse(paper.authors);
    const remainingAuthorsLength = authorArray.slice(1).length;
    var authorString = `${authorArray.slice(0, 1).join(", ")}${
      remainingAuthorsLength ? " + " + remainingAuthorsLength : ""
    }`;
  } catch (error) {
    // console.error(error);
    // console.warn(paper?.authors ?? paper);
  }

  const items = [
    paper.published ? utilities.paperTime(paper.published) : undefined,
    org ? paper.org : undefined,
    authors ? authorString : undefined,
    publisher ? menuOptionsPublisher[paper.publisher] : undefined
  ].filter(defined => defined);

  return (
    <Stack useFlexGap direction="row" alignItems="center" flexWrap="wrap">
      {items.map((content, index, array) => (
        <Fragment key={index}>
          {content}
          {index + 1 === array.length ? undefined : (
            <Box component="span" mx={0.5}>
              ·
            </Box>
          )}
        </Fragment>
      ))}
    </Stack>
  );
}
export const Header = ({
  paper,
  size = "medium",
  noStar,
  ...subheaderProps
}) => (
  <CardHeader
    avatar={
      Number.isFinite(paper.scoreTrending) ||
      Number.isFinite(paper.scoreSimilarity) ? (
        <GameBadge
          scoreTrending={paper.scoreTrending}
          scoreSimilarity={paper.scoreSimilarity}
          models={paper.models}
        />
      ) : undefined
    }
    title={<RenderPaperTitle paperTitle={paper.title} />}
    titleTypographyProps={{
      variant: "headlineMd",
      color: "surface.on.color",
      sx: theme => theme.typography.lineClamp(3),
      typography: {
        compact: size === "small" ? "labelMd" : "titleMd",
        expanded:
          size === "small"
            ? "labelLg"
            : size === "large"
            ? "titleLg"
            : size === "xl"
            ? "titleLg"
            : "titleSm",
        large:
          size === "small"
            ? "labelLg"
            : size === "large"
            ? "titleLg"
            : size === "xl"
            ? "headlineMd"
            : "titleMd"
      }
    }}
    subheader={<SubHeader paper={paper} size={size} {...subheaderProps} />}
    subheaderTypographyProps={{
      color: "surface.on.color",
      typography: {
        compact: "bodySm",
        expanded:
          size === "xl" ? "bodyLg" : size === "small" ? "bodySm" : "bodyMd"
      }
    }}
    action={
      noStar ? undefined : (
        <Box px={1}>
          <Star size={size} entity={paper} />
        </Box>
      )
    }
    sx={{ p: 0, alignItems: "flex-start" }}
  />
);
function Summary({ paper, size = "small" }) {
  const mlSummary = paper?.summaries?.[0]?.summary;
  const summary = textRank(
    mlSummary ?? paper.summary,
    size === "large" ? 3 : undefined
  );

  return summary ? (
    <Typography
      paragraph
      color="surface.on.color"
      sx={theme => ({
        typography: {
          compact: size === "small" ? "paperBody2" : "bodyMd",
          expanded: size === "small" ? "paperBody2" : "bodyLg"
        },
        ...theme.typography.lineClamp(size === "small" ? 2 : 3)
      })}
    >
      {summary}
    </Typography>
  ) : null;
}
